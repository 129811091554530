/* InvoiceCard.css */
.invoice-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    background-color: #f9f9f9;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .invoice-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .invoice-card p {
    margin: 5px 0;
  }

  .invoice-card p strong {
    color: #333;
  }
